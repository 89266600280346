export default {
  learn_more: {
    en: 'Learn more',
    fr: 'En savoir plus',
  },
  previous: {
    en: 'Previous',
    fr: 'Précédent',
  },
  next: {
    en: 'Next',
    fr: 'Suivant',
  },
};
